.breadcrumb_area {
    background-image: linear-gradient(180deg, rgb(94, 44, 237) 0%, rgb(164, 133, 253) 100%);
    position: relative;
    z-index: 1;
    padding: 235px 0px 125px;
    overflow: hidden;
  }
  .breadcrumb_area .breadcrumb_shap {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  
  /*================= End breadcrumb_area css =================*/
  /*================= Start breadcrumb_area_two css =================*/
  .breadcrumb_area_two {
    background: #f8f6fe;
    position: relative;
    padding: 235px 0px 125px;
    z-index: 1;
  }
  .breadcrumb_area_two .breadcrumb_shap {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    background-size: cover;
  }
  .breadcrumb_area_two .bubble li {
    background: #5e2ced;
    opacity: 0.1;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
  }
  .breadcrumb_area_two .bubble li:nth-child(1) {
    left: 220px;
    bottom: 50px;
    animation: spin3 2s infinite alternate;
  }
  .breadcrumb_area_two .bubble li:nth-child(2) {
    left: 35%;
    top: 100px;
    animation: spin 2s infinite alternate;
  }
  .breadcrumb_area_two .bubble li:nth-child(3) {
    left: 40%;
    width: 51px;
    height: 51px;
    bottom: 50px;
    animation: spin1 3s infinite alternate;
  }
  .breadcrumb_area_two .bubble li:nth-child(4) {
    left: 42%;
    bottom: 50px;
    width: 20px;
    height: 20px;
  }
  .breadcrumb_area_two .bubble li:nth-child(5) {
    left: 76%;
    width: 51px;
    height: 51px;
    top: 160px;
    animation: spin1 3s infinite alternate;
  }
  .breadcrumb_area_two .bubble li:nth-child(6) {
    left: 75%;
    width: 30px;
    height: 30px;
    top: 186px;
  }
  .breadcrumb_area_two .bubble li:nth-child(7) {
    left: 52%;
    top: 150px;
    width: 20px;
    height: 20px;
    animation: spin2 2s infinite alternate;
  }
  .breadcrumb_area_two .bubble li:nth-child(8) {
    left: 90%;
    top: 250px;
    width: 20px;
    height: 20px;
    animation: spin2 2s infinite alternate;
  }
  
  .breadcrumb_content_two h1 {
    color: #222d39;
    margin-bottom: 12px;
  }
  .breadcrumb_content_two .breadcrumb {
    background: transparent;
    justify-content: center;
  }
  .breadcrumb_content_two .breadcrumb li {
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
    position: relative;
    color: #525c7c;
  }
  .breadcrumb_content_two .breadcrumb li a {
    color: #051441;
  }
  .breadcrumb_content_two .breadcrumb li + li {
    padding-left: 32px;
  }
  .breadcrumb_content_two .breadcrumb li + li:after {
    content: "\e649";
    font-family: "themify";
    position: absolute;
    left: 8px;
    top: 0;
    font-size: 14px;
  }
  
  @keyframes spin {
    0% {
      transform: translateX(-10px) scale(0.9);
    }
    100% {
      transform: translateX(30px) scale(1.3) translateY(10px);
    }
  }
  @keyframes spin1 {
    0% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1.5);
    }
  }
  @keyframes spin2 {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(40px);
    }
  }
  @keyframes spin3 {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateY(10px);
    }
    50% {
      transform: translateX(30px);
    }
    100% {
      transform: translateX(50px);
    }
  }
  /*================= End breadcrumb_area_two css =================*/
  /*========= blog_breadcrumb_area css ========*/
  .blog_breadcrumb_area {
    position: relative;
    height: 600px;
    display: flex;
    align-items: center;
    z-index: 1;
  }
  .blog_breadcrumb_area .background_overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(-140deg, rgb(94, 44, 237) 0%, rgb(128, 55, 218) 100%);
    opacity: 0.9;
    z-index: -1;
    top: 0;
  }
  .blog_breadcrumb_area .breadcrumb_content_two {
    color: #fff;
    padding-top: 80px;
  }
  .blog_breadcrumb_area .breadcrumb_content_two h5 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 18px;
    color: #fff;
  }
  .blog_breadcrumb_area .breadcrumb_content_two h1 {
    font-size: 50px;
    font-weight: 700;
    line-height: 1.25em;
    color: #fff;
    margin-bottom: 25px;
  }
  .blog_breadcrumb_area .breadcrumb_content_two ol {
    margin-bottom: 0;
  }
  .blog_breadcrumb_area .breadcrumb_content_two ol li {
    display: inline-block;
    font-size: 16px;
  }
  .blog_breadcrumb_area .breadcrumb_content_two ol li + li:before {
    content: "\e649";
    display: inline-block;
    font-family: "themify";
    font-size: 10px;
    margin: 0px 5px 0px 3px;
  }

  .blog_area_two .blog_list_item:not(:last-child) {
    margin-bottom: 70px;
  }
  .blog_area_two .shop_page_number {
    border-top: 1px solid #ebe8f1;
    padding-top: 35px;
    margin-top: 0px;
  }
  
  .blog_list_item_two {
    position: relative;
  }
  .blog_list_item_two .post_date {
    position: absolute;
    left: 20px;
    top: 20px;
    border-radius: 4px;
    background-color: rgb(251, 251, 253);
    box-shadow: 0px 14px 30px 0px rgba(68, 28, 183, 0.25);
    text-align: center;
    color: #5e2ced;
    padding: 13px 26px;
    z-index: 1;
  }
  .blog_list_item_two .post_date h2 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .blog_list_item_two .post_date span {
    display: block;
    font-size: 15px;
    font-weight: 400;
  }
  .blog_list_item_two a img {
    border-radius: 6px;
  }
  .blog_list_item_two .video_post {
    position: relative;
    z-index: 0;
  }
  .blog_list_item_two .video_post:before {
    content: "";
    background: #282835;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.5;
    border-radius: 6px;
  }
  .blog_list_item_two .video_post img {
    border-radius: 6px;
  }
  .blog_list_item_two .video_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .blog_list_item_two .video_icon:after {
    display: none;
  }
  .blog_list_item_two .video_icon:before {
    transform: scale(1.5);
  }
  .blog_list_item_two .video_icon i {
    color: #794afd;
    text-shadow: 0px 6px 25px rgba(121, 74, 253, 0.9);
  }
  .blog_list_item_two .blog_content {
    box-shadow: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .blog_list_item_two .blog_content p {
    font-size: 16px;
    margin-bottom: 25px;
  }
  .blog_list_item_two .blog_content .post-info-bottom {
    display: flex;
    justify-content: space-between;
  }
  .blog_list_item_two .blog_content .post-info-bottom .post-info-comments {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #282835;
  }
  .blog_list_item_two .blog_content .post-info-bottom .post-info-comments i {
    color: #5e2ced;
    padding-right: 5px;
  }
  .blog_list_item_two .blog_content .learn_btn_two {
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 0px;
    line-height: 20px;
    display: inline-block;
    font-weight: 500;
  }
  .blog_list_item_two .blog_content .post-info-comments {
    text-transform: uppercase;
    line-height: 20px;
    display: inline-block;
  }
  
  .blog_title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    color: #222d39;
    font-family: "Poppins", sans-serif;
    margin-bottom: 15px;
  }
  .blog_title:hover {
    color: #5e2ced;
  }
  .blog_content {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.06);
    padding: 30px 47px 42px 50px;
    position: relative;
  }
  .blog_content .post_date {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0px 3px 0px 0px rgba(12, 0, 46, 0.06);
    text-align: center;
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    right: 50px;
    top: -40px;
    background: #fff;
  }
  .blog_content .post_date h2 {
    font: 700 30px/20px "Poppins", sans-serif;
    margin-bottom: 0px;
    color: #5e2ced;
    padding-top: 5px;
  }
  .blog_content .post_date span {
    font-weight: 300;
    font-size: 14px;
    display: block;
    padding-top: 5px;
  }
  .blog_content p {
    font-weight: 400;
    font-size: 15px;
  }
  .blog_content h5:hover {
    color: #5e2ced;
  }
  .blog_content .learn_btn_two {
    font: 400 14px "Poppins", sans-serif;
    color: #282835;
  }
  .blog_content .learn_btn_two:before {
    transition: all 0.2s linear;
  }
  .blog_content .learn_btn_two i {
    font-size: 14px;
  }
  .blog_content .learn_btn_two:hover {
    color: #5e2ced;
  }
  .blog_content .learn_btn_two:hover:before {
    background: #5e2ced;
  }
  
  
  .qutoe_post .blog_content {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 10px 30px 0px rgba(20, 3, 67, 0.08);
    border-radius: 6px;
    padding: 28px 40px 42px 40px;
    position: relative;
    overflow: hidden;
    border-left: 6px solid #5e2ced;
    z-index: 0;
  }
  .qutoe_post .blog_content:after {
    content: "\f10d";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 30px;
    bottom: 0px;
    color: #f2effd;
    font-size: 100px;
    transform: rotate(180deg);
    z-index: -1;
  }
  .qutoe_post .blog_content i {
    font-size: 30px;
    color: #5e2ced;
    padding-bottom: 12px;
    display: inline-block;
  }
  .qutoe_post .blog_content h6 {
    font-size: 20px;
    color: #525c7c;
    font-weight: 500;
    line-height: 34px;
    margin-bottom: 0;
  }
  .qutoe_post .blog_content .author_name {
    font-size: 14px;
    color: #848ca5;
    line-height: 20px;
    letter-spacing: 1px;
    margin-top: 22px;
    display: inline-block;
    text-transform: uppercase;
  }
  .qutoe_post.qutoe_post_two .blog_content:after {
    content: "\e02c";
    font-family: "ElegantIcons";
    font-size: 150px;
    right: 0;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  .qutoe_post.qutoe_post_two .blog_content .blog_title {
    font-weight: 500;
  }
  
  .blog_grid_info .blog_list_item {
    margin-bottom: 60px;
  }
  .blog_grid_info .blog_list_item .post_date {
    padding: 7px 19px;
  }
  .blog_grid_info .blog_list_item .post_date h2 {
    font-size: 24px;
  }
  .blog_grid_info .blog_list_item .blog_content {
    padding-top: 25px;
  }
  .blog_grid_info .blog_list_item .blog_content .blog_title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .blog_grid_info .blog_list_item.qutoe_post .blog_content {
    padding-bottom: 80px;
  }
  .blog_grid_info .blog_list_item.qutoe_post .blog_content:after {
    bottom: 0px;
    font-size: 90px;
    line-height: 90px;
  }
  .blog_grid_info .blog_list_item.qutoe_post_two .blog_content:after {
    bottom: auto;
    top: 80%;
  }
  
  .blog_single_info .blog_list_item_two .blog_content {
    padding-top: 50px;
  }
  .blog_single_info .blog_list_item_two .blog_content .qutoe_post {
    margin: 45px 0px;
  }
  .blog_single_info .blog_list_item_two .blog_content .post-info-bottom {
    padding-top: 20px;
  }
  .blog_single_info .blog_list_item_two .blog_content .post-info-bottom .social_icon {
    display: flex;
    text-transform: uppercase;
    font-size: 13px;
    color: #424255;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .blog_single_info .blog_list_item_two .blog_content .post-info-bottom .social_icon ul {
    display: inline-block;
  }
  .blog_single_info .blog_list_item_two .blog_content .post-info-bottom .social_icon ul li {
    display: inline-block;
    margin-left: 8px;
  }
  .blog_single_info .blog_list_item_two .blog_content .post-info-bottom .social_icon ul li a {
    font-size: 12px;
    color: #848ca5;
  }
  .blog_single_info .blog_list_item_two .blog_content .post-info-bottom .social_icon ul li a:hover {
    color: #5e2ced;
  }
  .blog_single_info .blog_titles {
    font: 600 20px "Poppins", sans-serif;
    color: #282835;
    margin-bottom: 30px;
  }
  .blog_single_info blockquote {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 10px 30px 0px rgba(20, 3, 67, 0.08);
    border-radius: 6px;
    padding: 28px 40px 22px 40px;
    position: relative;
    overflow: hidden;
    border-left: 6px solid #5e2ced;
    z-index: 0;
    margin: 40px 0px 45px;
  }
  .blog_single_info blockquote:before {
    content: "\f10d";
    font-size: 30px;
    color: #5e2ced;
    padding-bottom: 12px;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }
  .blog_single_info blockquote:after {
    content: "\f10d";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 30px;
    bottom: auto;
    top: 80%;
    color: #f2effd;
    font-size: 100px;
    transform: rotate(180deg);
    z-index: -1;
  }
  .blog_single_info blockquote i {
    font-size: 30px;
    color: #5e2ced;
    padding-bottom: 12px;
    display: inline-block;
  }
  .blog_single_info blockquote h6 {
    font-size: 20px;
    color: #525c7c;
    font-weight: 500;
    line-height: 34px;
    margin-bottom: 0;
  }
  .blog_single_info blockquote .author_name {
    font-size: 14px;
    color: #848ca5;
    line-height: 20px;
    letter-spacing: 1px;
    margin-top: 22px;
    display: inline-block;
    text-transform: uppercase;
  }
  
  .post_author_two {
    background: #f3f5fa;
    border-radius: 4px;
    padding: 35px 40px;
    align-items: center;
  }
  .post_author_two .img_rounded {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-right: 30px;
  }
  .post_author_two .media-body .comment_info h3 {
    font: 500 18px/24px "Poppins", sans-serif;
    color: #282835;
  }
  .post_author_two .media-body .comment_info .comment-date {
    font: 400 14px "Poppins", sans-serif;
    color: #677294;
  }
  .post_author_two .media-body p {
    margin-bottom: 0;
    font-size: 16px;
    color: #525c7c;
  }
  
  .blog_related_post {
    margin-top: 100px;
  }
  .blog_related_post.blog_grid_info .blog_list_item .blog_content {
    padding-top: 25px;
  }
  .blog_related_post.blog_grid_info .blog_list_item .blog_content h5 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 8px;
  }
  .blog_related_post.blog_grid_info .blog_list_item .blog_content p {
    font-size: 15px;
  }
  
  .comment_inner {
    margin-top: 40px;
  }
  .comment_inner .comment_box {
    margin-bottom: 0;
  }
  .comment_inner .comment_box .post_comment {
    background: #f7f8fb;
    padding: 30px 30px 30px;
  }
  .comment_inner .comment_box .post_comment .post_author_two {
    padding: 0px;
    background: transparent;
    align-items: inherit;
  }
  .comment_inner .comment_box .post_comment .post_author_two .comment_info {
    display: inline-block;
  }
  .comment_inner .comment_box .post_comment .post_author_two .comment_info h3 {
    margin-bottom: 0px;
  }
  .comment_inner .comment_box .post_comment .post_author_two .img_rounded {
    width: 70px;
    height: 70px;
  }
  .comment_inner .comment_box .post_comment .post_author_two .comment_reply {
    float: right;
    font: 500 13px/26px "Poppins", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #424255;
  }
  .comment_inner .comment_box .post_comment .post_author_two .comment_reply i {
    vertical-align: middle;
    font-size: 20px;
    padding-left: 5px;
  }
  .comment_inner .comment_box .post_comment .post_author_two .comment_reply:hover {
    color: #5e2ced;
  }
  .comment_inner .comment_box .post_comment .post_author_two p {
    padding-top: 12px;
  }
  .comment_inner .comment_box .post_comment .reply_comment {
    margin-left: 100px;
    border-top: 1px solid #e8ebf4;
    margin-top: 30px;
  }
  .comment_inner .comment_box .post_comment .reply_comment .post_author_two {
    padding-left: 0px;
    padding-right: 0;
    padding-top: 30px;
  }
  .comment_inner .comment_box .post_comment:not(:last-child) {
    margin-bottom: 30px;
  }
  
  .blog_comment_box {
    margin-top: 100px;
  }
  .blog_comment_box .get_quote_form .form-group .form-control {
    background: #f7f8fb;
    box-shadow: none;
  }
  .blog_comment_box .get_quote_form .form-group .form-control.placeholder {
    color: #9ca3b9;
  }
  .blog_comment_box .get_quote_form .form-group .form-control:-moz-placeholder {
    color: #9ca3b9;
  }
  .blog_comment_box .get_quote_form .form-group .form-control::-moz-placeholder {
    color: #9ca3b9;
  }
  .blog_comment_box .get_quote_form .form-group .form-control::-webkit-input-placeholder {
    color: #9ca3b9;
  }
  .blog_comment_box .get_quote_form .form-group .form-control:focus {
    border-color: #5e2ced;
    background: #fff;
  }
  
  .comments_widget ul {
    margin-bottom: 0;
  }
  .comments_widget ul li .comments_items .round_img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 30px;
    margin-top: 7px;
  }
  .comments_widget ul li .comments_items .media-body p {
    font-size: 15px;
    line-height: 26px;
    color: #282835;
    margin-bottom: 0px;
  }
  .comments_widget ul li .comments_items .media-body p:hover {
    color: #5e2ced;
  }
  .comments_widget ul li .comments_items .media-body span {
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    color: #848ca5;
    display: block;
    padding-top: 12px;
  }
  .comments_widget ul li:not(:last-child) {
    margin-bottom: 30px;
  }
  
  .widget_recent_comments #recentcomments {
    padding: 0px;
    list-style: none;
    margin-bottom: 0;
  }
  .widget_recent_comments #recentcomments .recentcomments {
    position: relative;
    padding-left: 90px;
    font-size: 15px;
    line-height: 26px;
    color: #282835;
    font-family: "Poppins", sans-serif;
    min-height: 66px;
  }
  .widget_recent_comments #recentcomments .recentcomments:before {
    content: "v";
    font-family: eleganticons;
    border: 1px solid #5e2ced;
    text-align: center;
    line-height: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 20px;
    color: #5e2ced;
    transition: background 0.3s linear, color 0.3s linear;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 6px;
  }
  .widget_recent_comments #recentcomments .recentcomments .comment-author-link {
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    color: #848ca5;
  }
  .widget_recent_comments #recentcomments .recentcomments a {
    color: #282835;
  }
  .widget_recent_comments #recentcomments .recentcomments a:hover {
    color: #5e2ced;
  }
  .widget_recent_comments #recentcomments .recentcomments:hover:before {
    background: #5e2ced;
    color: #fff;
  }
  .widget_recent_comments #recentcomments .recentcomments:not(:last-child) {
    margin-bottom: 30px;
  }
  
  .media {
    display: flex;
  }
  .media .media-body {
    flex: 1;
  }

  .f_p {
    font-family: "Poppins", sans-serif;
  }
  
  /*=========== font-weight ==============*/
  .f_300 {
    font-weight: 300;
  }
  
  .f_400 {
    font-weight: 400;
  }
  
  .f_500 {
    font-weight: 500;
  }
  
  .f_600 {
    font-weight: 600;
  }
  
  .f_700 {
    font-weight: 700;
  }
  
  .f_900 {
    font-weight: 900;
  }
  
  .t_color {
    color: #051441;
  }
  
  .w_color {
    color: #fff;
  }
  
  .t_color2 {
    color: #3f4451;
  }
  
  .t_color3 {
    color: #222d39;
  }
  
  .d_p_color {
    color: #bdbed6;
  }
  
  .bg_color {
    background: #fbfbfd;
  }
  
  .dk_bg_one {
    background: #090a21;
  }
  
  .dk_bg_two {
    background: #0f1029;
  }
  
  .seo_title_color {
    color: #263b5e;
  }
  
  .mt_60 {
    margin-top: 60px;
  }
  
  .mt_30 {
    margin-top: 30px;
  }
  
  .mt_40 {
    margin-top: 40px;
  }
  
  .mt_70 {
    margin-top: 70px;
  }
  
  .mt_75 {
    margin-top: 75px;
  }
  
  .mt_100 {
    margin-top: 100px;
  }
  
  .mt_130 {
    margin-top: 130px;
  }
  
  .mb_70 {
    margin-bottom: 70px;
  }
  
  .mb_20 {
    margin-bottom: 20px;
  }
  
  .mb_15 {
    margin-bottom: 15px;
  }
  
  .mb_30 {
    margin-bottom: -30px;
  }
  
  .mb-30 {
    margin-bottom: 30px;
  }
  
  .mb_40 {
    margin-bottom: 40px;
  }
  
  .mb_50 {
    margin-bottom: 50px;
  }
  
  .mb-50 {
    margin-bottom: -50px;
  }
  
  .mb_60 {
    margin-bottom: 60px;
  }
  
  .mb_90 {
    margin-bottom: 90px;
  }
  
  .pl_20 {
    padding-left: 20px;
  }
  
  .pl-30 {
    padding-left: 30px;
  }
  
  .pl_40 {
    padding-left: 40px;
  }
  
  .pl_50 {
    padding-left: 50px;
  }
  
  .pl_100 {
    padding-left: 100px;
  }
  
  .pl_70 {
    padding-left: 70px;
  }
  
  .pl_120 {
    padding-left: 120px;
  }
  
  .pr_100 {
    padding-right: 100px;
  }
  
  .pr_120 {
    padding-right: 120px;
  }
  
  .pr_70 {
    padding-right: 70px;
  }
  
  .pr_50 {
    padding-right: 50px;
  }
  
  .pr_20 {
    padding-right: 20px;
  }
  
  .pt_150 {
    padding-top: 150px;
  }
  
  .pt_120 {
    padding-top: 40px;
  }
  
  .sec_pad {
    padding: 120px 0px;
  }
  @media (min-width: 1400px) {
	.col-xxl {
		flex: 1 0 0%;
	}
	.row-cols-xxl-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	.row-cols-xxl-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	.row-cols-xxl-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	.row-cols-xxl-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.row-cols-xxl-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	.row-cols-xxl-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	.row-cols-xxl-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-xxl-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-xxl-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	.col-xxl-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	.col-xxl-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-xxl-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	.col-xxl-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	.col-xxl-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-xxl-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	.col-xxl-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	.col-xxl-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-xxl-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	.col-xxl-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	.col-xxl-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-xxl-0 {
		margin-left: 0;
	}
	.offset-xxl-1 {
		margin-left: 8.33333333%;
	}
	.offset-xxl-2 {
		margin-left: 16.66666667%;
	}
	.offset-xxl-3 {
		margin-left: 25%;
	}
	.offset-xxl-4 {
		margin-left: 33.33333333%;
	}
	.offset-xxl-5 {
		margin-left: 41.66666667%;
	}
	.offset-xxl-6 {
		margin-left: 50%;
	}
	.offset-xxl-7 {
		margin-left: 58.33333333%;
	}
	.offset-xxl-8 {
		margin-left: 66.66666667%;
	}
	.offset-xxl-9 {
		margin-left: 75%;
	}
	.offset-xxl-10 {
		margin-left: 83.33333333%;
	}
	.offset-xxl-11 {
		margin-left: 91.66666667%;
	}
	.g-xxl-0,
	.gx-xxl-0 {
		--bs-gutter-x: 0;
	}
	.g-xxl-0,
	.gy-xxl-0 {
		--bs-gutter-y: 0;
	}
	.g-xxl-1,
	.gx-xxl-1 {
		--bs-gutter-x: 0.25rem;
	}
	.g-xxl-1,
	.gy-xxl-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-xxl-2,
	.gx-xxl-2 {
		--bs-gutter-x: 0.5rem;
	}
	.g-xxl-2,
	.gy-xxl-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-xxl-3,
	.gx-xxl-3 {
		--bs-gutter-x: 1rem;
	}
	.g-xxl-3,
	.gy-xxl-3 {
		--bs-gutter-y: 1rem;
	}
	.g-xxl-4,
	.gx-xxl-4 {
		--bs-gutter-x: 1.5rem;
	}
	.g-xxl-4,
	.gy-xxl-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-xxl-5,
	.gx-xxl-5 {
		--bs-gutter-x: 3rem;
	}
	.g-xxl-5,
	.gy-xxl-5 {
		--bs-gutter-y: 3rem;
	}
}
.blog_grid_item {
    cursor: pointer;
  }
  .blog_grid_item img {
    transition: opacity 600ms linear;
  }
  .blog_grid_item:hover .blog_img img {
    opacity: 0.7;
  }
  
  .blog_img {
    background: #000022;
  }
  .blog_img img {
    max-width: 100%;
  }
  
  .blog_content {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.06);
    padding: 30px 47px 42px 50px;
    position: relative;
  }
  .blog_content .post_date {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0px 3px 0px 0px rgba(12, 0, 46, 0.06);
    text-align: center;
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    right: 50px;
    top: -40px;
    background: #fff;
  }
  .blog_content .post_date h2 {
    font: 700 30px/20px "Poppins", sans-serif;
    margin-bottom: 0px;
    color: #5e2ced;
    padding-top: 5px;
  }
  .blog_content .post_date span {
    font-weight: 300;
    font-size: 14px;
    display: block;
    padding-top: 5px;
  }
  .blog_content p {
    font-weight: 400;
    font-size: 15px;
  }
  .blog_content h5:hover {
    color: #5e2ced;
  }
  .blog_content .learn_btn_two {
    font: 400 14px "Poppins", sans-serif;
    color: #282835;
  }
  .blog_content .learn_btn_two:before {
    transition: all 0.2s linear;
  }
  .blog_content .learn_btn_two i {
    font-size: 14px;
  }
  .blog_content .learn_btn_two:hover {
    color: #5e2ced;
  }
  .blog_content .learn_btn_two:hover:before {
    background: #5e2ced;
  }
  
  .entry_post_info {
    font: 400 15px/25px "Poppins", sans-serif;
    color: #677294;
    padding-bottom: 5px;
  }
  .entry_post_info a {
    color: #677294;
  }
  .entry_post_info a + a:before {
    content: "/";
    display: inline-block;
    margin: 0px 7px 0px 2px;
  }
  
  .blog_single .blog_content {
    padding-left: 0px;
    padding-right: 0px;
    box-shadow: none;
  }
  .blog_single .blockquote {
    color: #5e2ced;
    font-style: italic;
    border-left: 4px solid #5e2ced;
    padding-left: 35px;
  }
  
  .post_share {
    display: flex;
    border-bottom: 1px solid #d8dceb;
    padding-bottom: 10px;
  }
  .post_share a {
    font: 400 14px/26px "Poppins", sans-serif;
    color: #525c7c;
  }
  .post_share a i {
    padding-right: 12px;
  }
  .post_share a:hover {
    color: #5e2ced;
  }
  .post_share a + a {
    margin-left: 15px;
  }
  .post_share .flex {
    flex: 1;
    text-align: right;
  }
  
  .post-nam {
    font: 400 15px/26px "Poppins", sans-serif;
    color: #282835;
  }
  
  .post_tag {
    padding-top: 20px;
  }
  .post_tag .post-nam {
    padding-right: 10px;
  }
  .post_tag a {
    font: 400 13px/26px "Poppins", sans-serif;
    color: #595b64;
    border: 1px solid #cfcfe6;
    border-radius: 3px;
    padding: 0px 11px;
    display: inline-block;
    margin-left: 10px;
  }
  
  .post_author {
    display: flex;
    align-items: flex-start;
  }
  .post_author .media-body {
    padding-left: 20px;
    flex: 1;
  }
  .post_author .media-body h6 {
    color: #677294;
  }
  .post_author .media-body p {
    color: #677294;
    margin-bottom: 0px;
  }
  
  .blog_post_item .blog_content {
    padding: 22px 22px 35px;
  }
  .blog_post_item .blog_content h5 {
    margin-bottom: 15px;
  }
  
  .comment-box .post_comment .post_author {
    margin-bottom: 30px;
  }
  .comment-box .post_comment .post_author .media-left {
    margin-right: 30px;
  }
  .comment-box .post_comment .post_author .replay {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #525c7c;
    padding-top: 14px;
    transition: all 0.1s linear;
  }
  .comment-box .post_comment .post_author .replay:hover {
    color: #5e2ced;
  }
  .comment-box .post_comment .post_author .media-body {
    border-bottom: 1px solid #e8ebf4;
    padding-left: 0;
    padding-bottom: 25px;
  }
  .comment-box .post_comment .reply-comment {
    padding-left: 110px;
  }
  .comment-box .post_comment .reply-comment .post_author {
    margin-bottom: 0px;
  }
  
.shop_page_number {
    text-align: center;
    margin-bottom: 0px;
}
.mt_30 {
    margin-top: 30px;
}


.shop_page_number {
    text-align: center;
    margin-bottom: 0px;
  }
  .shop_page_number li {
    display: inline-block;
    margin: 0px 3px;
    
  }
  .shop_page_number li .page-numbers {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #e7e7f6;
    font-size: 16px;
    line-height: 39px;
    font-weight: 300;
    color: #677294;
    text-align: center;
    display: block;
    transition: all 0.2s linear;
  }
  .shop_page_number li .page-numbers:hover {
    border-color: #5e2ced;
    color: #5e2ced;
  }
  .shop_page_number li .page-numbers.current {
    background: #501f84;
    border-color: #5e2ced;
    color: #fff;
  }
 
  @media (min-width: 576px) {
    .col-sm {
      flex: 1 0 0%;
    }
    .row-cols-sm-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-sm-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-sm-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-sm-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-sm-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-sm-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-sm-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-sm-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-sm-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-sm-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-sm-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-sm-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-sm-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-sm-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-sm-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-sm-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-sm-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-sm-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-sm-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.33333333%;
    }
    .offset-sm-2 {
      margin-left: 16.66666667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.33333333%;
    }
    .offset-sm-5 {
      margin-left: 41.66666667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.33333333%;
    }
    .offset-sm-8 {
      margin-left: 66.66666667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.33333333%;
    }
    .offset-sm-11 {
      margin-left: 91.66666667%;
    }
    .g-sm-0,
    .gx-sm-0 {
      --bs-gutter-x: 0;
    }
    .g-sm-0,
    .gy-sm-0 {
      --bs-gutter-y: 0;
    }
    .g-sm-1,
    .gx-sm-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-sm-1,
    .gy-sm-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-sm-2,
    .gx-sm-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-sm-2,
    .gy-sm-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-sm-3,
    .gx-sm-3 {
      --bs-gutter-x: 1rem;
    }
    .g-sm-3,
    .gy-sm-3 {
      --bs-gutter-y: 1rem;
    }
    .g-sm-4,
    .gx-sm-4 {
      --bs-gutter-x: 1.5rem;
    }
    .g-sm-4,
    .gy-sm-4 {
      --bs-gutter-y: 1.5rem;
    }
    .g-sm-5,
    .gx-sm-5 {
      --bs-gutter-x: 3rem;
    }
    .g-sm-5,
    .gy-sm-5 {
      --bs-gutter-y: 3rem;
    }
  }
  @media (min-width: 768px) {
    .col-md {
      flex: 1 0 0%;
    }
    .row-cols-md-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-md-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-md-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-md-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-md-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-md-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-md-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-md-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-md-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-md-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-md-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-md-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-md-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-md-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-md-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-md-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-md-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.33333333%;
    }
    .offset-md-2 {
      margin-left: 16.66666667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.33333333%;
    }
    .offset-md-5 {
      margin-left: 41.66666667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.33333333%;
    }
    .offset-md-8 {
      margin-left: 66.66666667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.33333333%;
    }
    .offset-md-11 {
      margin-left: 91.66666667%;
    }
    .g-md-0,
    .gx-md-0 {
      --bs-gutter-x: 0;
    }
    .g-md-0,
    .gy-md-0 {
      --bs-gutter-y: 0;
    }
    .g-md-1,
    .gx-md-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-md-1,
    .gy-md-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-md-2,
    .gx-md-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-md-2,
    .gy-md-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-md-3,
    .gx-md-3 {
      --bs-gutter-x: 1rem;
    }
    .g-md-3,
    .gy-md-3 {
      --bs-gutter-y: 1rem;
    }
    .g-md-4,
    .gx-md-4 {
      --bs-gutter-x: 1.5rem;
    }
    .g-md-4,
    .gy-md-4 {
      --bs-gutter-y: 1.5rem;
    }
    .g-md-5,
    .gx-md-5 {
      --bs-gutter-x: 3rem;
    }
    .g-md-5,
    .gy-md-5 {
      --bs-gutter-y: 3rem;
    }
  }
  @media (min-width: 992px) {
    .col-lg {
      flex: 1 0 0%;
    }
    .row-cols-lg-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-lg-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-lg-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-lg-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-lg-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-lg-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-lg-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-lg-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-lg-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-lg-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-lg-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-lg-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-lg-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-lg-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.33333333%;
    }
    .offset-lg-2 {
      margin-left: 16.66666667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.33333333%;
    }
    .offset-lg-5 {
      margin-left: 41.66666667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.33333333%;
    }
    .offset-lg-8 {
      margin-left: 66.66666667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.33333333%;
    }
    .offset-lg-11 {
      margin-left: 91.66666667%;
    }
    .g-lg-0,
    .gx-lg-0 {
      --bs-gutter-x: 0;
    }
    .g-lg-0,
    .gy-lg-0 {
      --bs-gutter-y: 0;
    }
    .g-lg-1,
    .gx-lg-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-lg-1,
    .gy-lg-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-lg-2,
    .gx-lg-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-lg-2,
    .gy-lg-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-lg-3,
    .gx-lg-3 {
      --bs-gutter-x: 1rem;
    }
    .g-lg-3,
    .gy-lg-3 {
      --bs-gutter-y: 1rem;
    }
    .g-lg-4,
    .gx-lg-4 {
      --bs-gutter-x: 1.5rem;
    }
    .g-lg-4,
    .gy-lg-4 {
      --bs-gutter-y: 1.5rem;
    }
    .g-lg-5,
    .gx-lg-5 {
      --bs-gutter-x: 3rem;
    }
    .g-lg-5,
    .gy-lg-5 {
      --bs-gutter-y: 3rem;
    }
  }
  @media (min-width: 1200px) {
    .col-xl {
      flex: 1 0 0%;
    }
    .row-cols-xl-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-xl-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-xl-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-xl-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-xl-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-xl-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-xl-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-xl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-xl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-xl-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-xl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-xl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-xl-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-xl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-xl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-xl-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-xl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-xl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-xl-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.33333333%;
    }
    .offset-xl-2 {
      margin-left: 16.66666667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.33333333%;
    }
    .offset-xl-5 {
      margin-left: 41.66666667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.33333333%;
    }
    .offset-xl-8 {
      margin-left: 66.66666667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.33333333%;
    }
    .offset-xl-11 {
      margin-left: 91.66666667%;
    }
    .g-xl-0,
    .gx-xl-0 {
      --bs-gutter-x: 0;
    }
    .g-xl-0,
    .gy-xl-0 {
      --bs-gutter-y: 0;
    }
    .g-xl-1,
    .gx-xl-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-xl-1,
    .gy-xl-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-xl-2,
    .gx-xl-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-xl-2,
    .gy-xl-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-xl-3,
    .gx-xl-3 {
      --bs-gutter-x: 1rem;
    }
    .g-xl-3,
    .gy-xl-3 {
      --bs-gutter-y: 1rem;
    }
    .g-xl-4,
    .gx-xl-4 {
      --bs-gutter-x: 1.5rem;
    }
    .g-xl-4,
    .gy-xl-4 {
      --bs-gutter-y: 1.5rem;
    }
    .g-xl-5,
    .gx-xl-5 {
      --bs-gutter-x: 3rem;
    }
    .g-xl-5,
    .gy-xl-5 {
      --bs-gutter-y: 3rem;
    }
  }
  .widget_title {
    margin-bottom: 35px;
  }
  .widget_title .border_bottom {
    width: 180px;
    height: 1px;
    background: #677294;
    display: block;
  }
  
  .widget_title_two {
    font-size: 20px;
    font-weight: 600;
    color: #282835;
    margin-bottom: 30px;
  }
  
  .blog-sidebar {
    padding-left: 40px;
  }
  .blog-sidebar .widget.widget_search .search-form {
    position: relative;
  }
  .blog-sidebar .widget.widget_search .search-form .form-control {
    font: 300 14px "Poppins", sans-serif;
    color: #677294;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 3px 0px rgba(12, 0, 46, 0.08);
    border-radius: 0px;
    border: 0px;
    padding-left: 30px;
    height: 55px;
    z-index: 0;
    border: 1px solid transparent;
  }
  .blog-sidebar .widget.widget_search .search-form .form-control.placeholder {
    color: #677294;
  }
  .blog-sidebar .widget.widget_search .search-form .form-control:-moz-placeholder {
    color: #677294;
  }
  .blog-sidebar .widget.widget_search .search-form .form-control::-moz-placeholder {
    color: #677294;
  }
  .blog-sidebar .widget.widget_search .search-form .form-control::-webkit-input-placeholder {
    color: #677294;
  }
  .blog-sidebar .widget.widget_search .search-form .form-control:focus {
    border-color: #ccc5fa;
    border-radius: 3px;
  }
  .blog-sidebar .widget.widget_search .search-form button {
    border: 0px;
    border-radius: 0px;
    font-size: 16px;
    color: #282835;
    background: transparent;
    position: absolute;
    right: 20px;
    height: 100%;
    padding: 0px;
    z-index: 1;
    cursor: pointer;
  }
  .blog-sidebar .widget.widget_search .search-form button:hover {
    color: #5e2ced;
  }
  .blog-sidebar .widget.widget_recent_post .post_item img {
    border-radius: 3px;
  }
  .blog-sidebar .widget.widget_recent_post .post_item .media-body {
    padding-left: 20px;
    align-self: center;
    flex: 1;
  }
  .blog-sidebar .widget.widget_recent_post .post_item .media-body h3 {
    color: #282835;
  }
  .blog-sidebar .widget.widget_recent_post .post_item .media-body h3:hover {
    color: #5e2ced;
  }
  .blog-sidebar .widget.widget_recent_post .post_item .media-body .entry_post_info {
    padding-bottom: 0px;
  }
  .blog-sidebar .widget.widget_recent_post .post_item + .post_item {
    margin-top: 30px;
  }
  .blog-sidebar .widget.widget_categorie .widget_title {
    margin-bottom: 28px;
  }
  .blog-sidebar .widget.widget_categorie ul {
    margin-bottom: 0px;
  }
  .blog-sidebar .widget.widget_categorie ul li a {
    font: 400 16px/35px "Poppins", sans-serif;
    color: #282835;
    transition: all 0.2s linear;
  }
  .blog-sidebar .widget.widget_categorie ul li a span {
    border-bottom: 1px solid transparent;
    transition: all 0.3s linear;
  }
  .blog-sidebar .widget.widget_categorie ul li a em {
    float: right;
    font-style: normal;
  }
  .blog-sidebar .widget.widget_categorie ul li a:hover span {
    color: #5e2ced;
    border-color: #5e2ced;
  }
  .blog-sidebar .widget.widget_tag_cloud .post-tags {
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
  }
  .blog-sidebar .widget.widget_tag_cloud .post-tags a {
    font: 400 13px/26px "Poppins", sans-serif;
    color: #595b64;
    border: 1px solid #cfcfe6;
    border-radius: 3px;
    padding: 6px 18px;
    float: left;
    transition: all 0.2s linear;
    margin: 5px;
  }
  .blog-sidebar .widget.widget_tag_cloud .post-tags a:hover {
    background: #5e2ced;
    border-color: #5e2ced;
    color: #fff;
  }
  .blog-sidebar .widget.search_widget_two .search-form .form-control {
    padding: 0px 30px;
    background: #f3f4f9;
    border-radius: 4px;
    height: 60px;
    font-size: 15px;
    color: #677294;
    box-shadow: none;
    position: relative;
    z-index: 0;
    border-color: transparent;
    transition: all 0.3s linear;
  }
  .blog-sidebar .widget.search_widget_two .search-form .form-control.placeholder {
    color: #677294;
  }
  .blog-sidebar .widget.search_widget_two .search-form .form-control:-moz-placeholder {
    color: #677294;
  }
  .blog-sidebar .widget.search_widget_two .search-form .form-control::-moz-placeholder {
    color: #677294;
  }
  .blog-sidebar .widget.search_widget_two .search-form .form-control::-webkit-input-placeholder {
    color: #677294;
  }
  .blog-sidebar .widget.search_widget_two .search-form .form-control:focus {
    border-color: #5e2ced;
    background: #fff;
  }
  .blog-sidebar .widget.search_widget_two .search-form button {
    position: absolute;
    background: transparent;
    padding: 0px;
    border: 0px;
    right: 30px;
    top: 51%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #282835;
    z-index: 0;
  }
  .blog-sidebar .widget.recent_post_widget_two .post_item {
    align-items: center;
    display: flex;
  }
  .blog-sidebar .widget.recent_post_widget_two .post_item img {
    border-radius: 4px;
    padding-right: 25px;
  }
  .blog-sidebar .widget.recent_post_widget_two .post_item .media-body {
    flex: 1;
  }
  .blog-sidebar .widget.recent_post_widget_two .post_item .media-body h3 {
    font: 500 16px/22px "Poppins", sans-serif;
    color: #222d39;
    margin-bottom: 0;
  }
  .blog-sidebar .widget.recent_post_widget_two .post_item .media-body h3:hover {
    color: #5e2ced;
  }
  .blog-sidebar .widget.recent_post_widget_two .post_item .media-body .entry_post_info {
    font-size: 14px;
    color: #85859c;
    font-weight: 400;
    text-transform: uppercase;
  }
  .blog-sidebar .widget.recent_post_widget_two .post_item:not(:last-child) {
    margin-bottom: 30px;
  }
  .blog-sidebar .widget.categorie_widget_two ul {
    margin-bottom: 0;
  }
  .blog-sidebar .widget.categorie_widget_two ul li a {
    font-size: 14px;
    line-height: 36px;
    color: #282835;
    text-transform: uppercase;
  }
  .blog-sidebar .widget.categorie_widget_two ul li a em {
    font-style: normal;
    color: #282835;
    padding-left: 10px;
  }
  .blog-sidebar .widget.categorie_widget_two ul li a:hover {
    color: #5e2ced;
  }
  .blog-sidebar .widget.tag_widget_two .post-tags {
    margin: -5px -4px;
  }
  .blog-sidebar .widget.tag_widget_two .post-tags a {
    padding: 6px 20px;
    font-size: 14px;
    color: #5a5a77;
    background: #f0f2f9;
    border-radius: 4px;
    margin: 5px 4px;
    display: inline-block;
    transition: all 0.2s linear;
  }
  .blog-sidebar .widget.tag_widget_two .post-tags a:hover {
    background: #5e2ced;
    color: #fff;
  }
  
  .instagram_widget ul {
    margin: -7px;
    display: flex;
    flex-wrap: wrap;
  }
  .instagram_widget ul li {
    padding: 7px 6px;
    width: 33.3333333333%;
  }
  .instagram_widget ul li a {
    display: block;
    position: relative;
  }
  .instagram_widget ul li a:before {
    content: "\e73d";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: "themify";
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    background: rgba(94, 44, 237, 0.6);
    border-radius: 4px;
    opacity: 0;
    top: 0;
    transition: opacity 0.4s linear;
    color: #fff;
  }
  .instagram_widget ul li a img {
    max-width: 100%;
    border-radius: 4px;
  }
  .instagram_widget ul li a:hover:before {
    opacity: 1;
  }
  
  /*========= blog_area_two css ===========*/
  .blog_area_two .blog_list_item:not(:last-child) {
    margin-bottom: 70px;
  }
  .blog_area_two .shop_page_number {
    border-top: 1px solid #ebe8f1;
    padding-top: 35px;
    margin-top: 0px;
  }
  
  .blog_list_item_two {
    position: relative;
  }
  .blog_list_item_two .post_date {
    position: absolute;
    left: 20px;
    top: 20px;
    border-radius: 4px;
    background-color: rgb(251, 251, 253);
    box-shadow: 0px 14px 30px 0px rgba(68, 28, 183, 0.25);
    text-align: center;
    color: #5e2ced;
    padding: 13px 26px;
    z-index: 1;
  }
  .blog_list_item_two .post_date h2 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .blog_list_item_two .post_date span {
    display: block;
    font-size: 15px;
    font-weight: 400;
  }
  .blog_list_item_two a img {
    border-radius: 6px;
  }
  .blog_list_item_two .video_post {
    position: relative;
    z-index: 0;
  }
  .blog_list_item_two .video_post:before {
    content: "";
    background: #282835;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.5;
    border-radius: 6px;
  }
  .blog_list_item_two .video_post img {
    border-radius: 6px;
  }
  .blog_list_item_two .video_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .blog_list_item_two .video_icon:after {
    display: none;
  }
  .blog_list_item_two .video_icon:before {
    transform: scale(1.5);
  }
  .blog_list_item_two .video_icon i {
    color: #794afd;
    text-shadow: 0px 6px 25px rgba(121, 74, 253, 0.9);
  }
  .blog_list_item_two .blog_content {
    box-shadow: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .blog_list_item_two .blog_content p {
    font-size: 16px;
    margin-bottom: 25px;
  }
  .blog_list_item_two .blog_content .post-info-bottom {
    display: flex;
    justify-content: space-between;
  }
  .blog_list_item_two .blog_content .post-info-bottom .post-info-comments {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #282835;
  }
  .blog_list_item_two .blog_content .post-info-bottom .post-info-comments i {
    color: #5e2ced;
    padding-right: 5px;
  }
  .blog_list_item_two .blog_content .learn_btn_two {
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 0px;
    line-height: 20px;
    display: inline-block;
    font-weight: 500;
  }
  .blog_list_item_two .blog_content .post-info-comments {
    text-transform: uppercase;
    line-height: 20px;
    display: inline-block;
  }
  
  .blog_title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    color: #222d39;
    font-family: "Poppins", sans-serif;
    margin-bottom: 15px;
  }

  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #3c0c5e;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
    
     @media screen and (max-width:500px){
    
      .blog-sidebar{
        padding-left: 0px;
      }
      .col-lg-8{
        min-height: 50vh;
      }
    }